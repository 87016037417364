import opsYtkeApiAxiosInstance from "../OpsYtkeApi";

const urlPath = process.env.REACT_APP_WEB_API_BASE + '/applicationForm';

/**
 * Select / Update or create Application Form Data
 * @returns
 */


const applicationFormService = {
	getList: function (page = 0, size = 5, criteriaModel) {
		let criteriaBuilder = {};

		if (criteriaModel != null) {
			criteriaBuilder = Object.assign({}, criteriaModel);
		}

		return new Promise((resolve, reject) => {
			opsYtkeApiAxiosInstance.post(
				urlPath + "/search",
				criteriaBuilder,
				{
					params: {
						page: page,
						size: size,
					}
				}
			)
				.then((res) => {
					const mappedResponse = res.data;
					resolve(mappedResponse);
				}).catch((error) => {
					reject(error);
				});
		});
	},
	getSingle: function (applicationFormId) {
		return new Promise((resolve, reject) => {
			opsYtkeApiAxiosInstance.get(urlPath + "/" + applicationFormId)
				.then((res) => {
					const mappedResponse = res.data;
					resolve(mappedResponse);
				}).catch((error) => {
					reject(error);
				});
		});
	},
	update: function (applicationFormModel) {
		return new Promise((resolve, reject) => {
			opsYtkeApiAxiosInstance.put(
				urlPath,
				applicationFormModel
			).then((res) => {
				const mappedResponse = res.data;
				resolve(mappedResponse);
			})
				.catch((error) => {
					reject(error);
				});
		});
	},
	create: function (applicationFormModel, attachmentRef = null) {
		console.log(attachmentRef)
		return new Promise((resolve, reject) => {
			opsYtkeApiAxiosInstance.post(
				urlPath,
				applicationFormModel
			).then((res) => {
				const mappedResponse = res.data;
				resolve(mappedResponse);

				// Call handleSubmitFile if there are files to upload
				if (attachmentRef && attachmentRef.current && attachmentRef.current.length > 0) {
					this.handleSubmitFile(mappedResponse.id, attachmentRef)
						.then(() => console.log("File upload complete"))
						.catch(error => console.error("File upload error:", error));
				}
			}).catch((error) => {
				reject(error);
			});
		});
	},


	/**
	 * Handles file submission if a file reference exists.
	 * @param {number} applicationFormId - ID of the created application form.
	 * @param {object} fileRef - The reference to the file input.
	 */
	handleSubmitFile: function(applicationFormId, attachmentRef) {
		if (attachmentRef && attachmentRef.current && attachmentRef.current.length > 0) {
			const uploadPromises = attachmentRef.current.map(file => {
				const timestamp = new Intl.DateTimeFormat('en-GB', {
					day: '2-digit',
					month: '2-digit',
					year: 'numeric',
					hour: '2-digit',
					minute: '2-digit',
					hour12: false,
					timeZone: 'Europe/Athens'
				}).format(new Date()).replace(/[/, :]/g, '-');

				// Separate file name and extension
				const fileNameParts = file.name.split('.');
				const baseName = fileNameParts.slice(0, -1).join('.');
				const extension = fileNameParts[fileNameParts.length - 1];

				// Add timestamp to base name, keep extension separate
				const newFileName = `${baseName}-${timestamp}.${extension}`;

				const formData = new FormData();
				formData.append("filedata", file, newFileName);

				// Return the upload promise for each file
				return opsYtkeApiAxiosInstance.post(
					`${urlPath}/addApplicationFormFile/${applicationFormId}`,
					formData,
					{
						headers: {
							"Content-Type": "multipart/form-data",
						}
					}
				);
			});

			// Wait for all files to be uploaded
			return Promise.all(uploadPromises)
				.then(responses => {
					// Clear attachmentRef after all uploads complete
					attachmentRef.current = [];
					return responses.map(response => response.data);
				})
				.catch(error => {
					throw error;
				});
		} else {
			return Promise.resolve(null);
		}
	},




	approveBeneficiaryApplicationForm: function (applicationFormId) {
		return new Promise((resolve, reject) => {
			opsYtkeApiAxiosInstance.get(
				"/applicationFormManagement/approve/" + applicationFormId)
				.then((res) => {
					const mappedResponse = res.data;
					resolve(mappedResponse);
				}).catch((error) => {
					reject(error);
				});
		});
	},
	rejectBeneficiaryApplicationForm: function (applicationFormId) {
		return new Promise((resolve, reject) => {
			opsYtkeApiAxiosInstance.get(
				"/applicationFormManagement/reject/" + applicationFormId)
				.then((res) => {
					const mappedResponse = res.data;
					resolve(mappedResponse);
				}).catch((error) => {
					reject(error);
				});
		});
	},
	sendToBeneficiaryApplicationForm: function (applicationFormId, comments) {
		return new Promise((resolve, reject) => {
			opsYtkeApiAxiosInstance.post(
				"/applicationFormManagement/sendBackToBeneficiary/" + applicationFormId,
				{ "comments": comments }
			)
				.then((res) => {
					const mappedResponse = res.data;
					resolve(mappedResponse);
				}).catch((error) => {
					reject(error);
				});
		});
	},
	sendToBackOfficerApplicationForm: function (applicationFormId) {
		return new Promise((resolve, reject) => {
			opsYtkeApiAxiosInstance.get(
				urlPath + "/sendBackToBackOfficer/" + applicationFormId)
				.then((res) => {
					const mappedResponse = res.data;
					resolve(mappedResponse);
				}).catch((error) => {
					reject(error);
				});
		});
	},
	saveFile: function (applicationFormId, file) {
		return opsYtkeApiAxiosInstance.post(
			urlPath + "/addApplicationFormFile/" + applicationFormId,
			{ 
				"comments": file 
			},
			{
				headers: { "Content-Type": "multipart/form-data" }
			}
		);
	},
	getFile: function (applicationFormId) {
		return opsYtkeApiAxiosInstance.get(
			urlPath + "/getApplicationFormFile/" + applicationFormId
		);
	},
};

export default applicationFormService;
