import React from 'react';
import {Button, ButtonGroup, Card, Col, Row} from "@themesberg/react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave, faTimesCircle, faCheck, faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import i18next from "../../translations/i18nConfigInstance";
import {FormActionsPromptsModule} from "../genericFormActionPromptsModule/FormActionsPromptsModule";
import {withRouter} from "react-router-dom";

type Props = {
    isSaveButtonDisabled: boolean,
    onValidateCallback: Function,
    saveService: Function,
    formIsEditMode: boolean,
    formIsCreateMode: boolean,
    navigationUrl: String,
    showApproveButton: boolean,
    isApproveButtonDisabled: boolean,
    approveService: Function,
    showRejectButton: boolean,
    isRejectButtonDisabled: boolean,
    rejectService: Function,
    showSendToButton: boolean,
    isSendToButtonDisabled: boolean,
    sendToService: Function,
    sendToButtonTitle: string
}

class GenericFormMenuModule extends React.Component<Props> {
    state = {
        // Prompts State
        showCancelFormPrompt: false,
        showSaveFormPrompt: false,
        showPendingFormPrompt: false,
        showSuccessSaveFormPrompt: false,
        showApproveFormPrompt: false,
        showSuccessApproveFormPrompt: false,
        showRejectFormPrompt: false,
        showSuccessRejectFormPrompt: false,
        showSendToFormPrompt: false,
        showSuccessSendToFormPrompt: false,
    };

    /**
     * Triggers the Approve Service
     */
    onApproveAction = () => {
        const {
            approveService
        } = this.props;

        this.onClosingApproveFormPrompt();

        this.pendingPromptToggle(true);

        approveService()
            .then((successResponseFromRequest) => {

                this.pendingPromptToggle(false);

                this.openSuccessApproveFormPrompt();

            }).catch((errorResponseFromRequest) => {
                this.pendingPromptToggle(false);
            })
    }

    /**
     * Triggers the Reject Service
     */
    onRejectAction = () => {
        const {
            rejectService
        } = this.props;

        this.onClosingRejectFormPrompt();

        this.pendingPromptToggle(true);

        rejectService()
            .then((successResponseFromRequest) => {

                this.pendingPromptToggle(false);

                this.openSuccessRejectFormPrompt();

            }).catch((errorResponseFromRequest) => {
            this.pendingPromptToggle(false);
        })
    }

    /**
     * Triggers the SendTo Service
     */
    onSendToAction = (comments) => {
        const {
            sendToService
        } = this.props;

        this.onClosingSendToFormPrompt();

        this.pendingPromptToggle(true);

        sendToService(comments)
            .then((successResponseFromRequest) => {

                this.pendingPromptToggle(false);

                this.openSuccessSendToFormPrompt();

            }).catch((errorResponseFromRequest) => {
            this.pendingPromptToggle(false);
        })
    }

    /**
     * Actual Form Save Action in 2 steps
     * 1) Call the Validate Form (Make business validations, prepare data etc) and handle the promise returned
     * 2) Call the create/update service and handle the promise returned
     */
    onSaveAction = () => {
        const {
            onValidateCallback,
            saveService,
            formIsCreateMode,
        } = this.props;

        const attachmentRef = formIsCreateMode ? this.props.attachmentRef : undefined;

        onValidateCallback()
            .then((response) => {
                this.onClosingSaveFormPrompt();
                this.pendingPromptToggle(true);

                // Call saveService with the fileRef if in create mode
                saveService(response, attachmentRef)
                    .then((successResponseFromRequest) => {
                        this.pendingPromptToggle(false);
                        this.openSuccessSaveFormPrompt();
                    })
                    .catch((errorResponseFromRequest) => {
                        this.pendingPromptToggle(false);
                        this.onClosingSaveFormPrompt();
                    });
            })
            .catch((error) => {
                this.onClosingSaveFormPrompt();
            });
    };



    /**
     * Just controls state to show/hide the Cancel Prompt Alert
     */
    cancelFormPromptToggle = (value) => {
        this.setState({
            showCancelFormPrompt: value,
        })
    };

    /**
     * Just controls state to show/hide the Pending Saving Prompt Alert
     */
    pendingPromptToggle = (value) => {
        this.setState({
            showPendingFormPrompt: value,
        })
    };

    openApproveFormPrompt = () => {
        this.setState({
            showApproveFormPrompt: true,
        })
    }

    onClosingApproveFormPrompt = () => {
        this.setState({
            showApproveFormPrompt: false,
        })
    }

    openRejectFormPrompt = () => {
        this.setState({
            showRejectFormPrompt: true,
        })
    }

    onClosingRejectFormPrompt = () => {
        this.setState({
            showRejectFormPrompt: false,
        })
    }

    openSendToFormPrompt = () => {
        this.setState({
            showSendToFormPrompt: true,
        })
    }

    onClosingSendToFormPrompt = () => {
        this.setState({
            showSendToFormPrompt: false,
        })
    }


    /**
     * Just controls state to show the Save Prompt Alert
     */
    openSaveFormPrompt = () => {
        this.setState({
            showSaveFormPrompt: true,
        })
    };

    /**
     * Closes Save Form Prompt dialog
     */
    onClosingSaveFormPrompt = () => {
        this.setState({
            showSaveFormPrompt: false,
        })
    };

    /**
     * What happens when user confirms the cancel action
     * user navigates to url passed by parent component
     */
    onConfirmCancelAction = () => {
        const {
            navigationUrl,

            // From withRouter HOC
            history
        } = this.props;

        this.cancelFormPromptToggle(false);
        history.push(navigationUrl)
    };

    /**
     * Opens the Success Approve modal
     */
    openSuccessApproveFormPrompt = () => {
        this.setState({
            showSuccessApproveFormPrompt: true,
        })
    }

    /**
     * Closes the Success Approve modal
     */
    onClosingSuccessApproveFormPrompt = () => {
        const {
            navigationUrl,
            history
        } = this.props;

        this.setState({
            showSuccessApproveFormPrompt: false,
        });

        // Navigate to Search Page
        history.push(navigationUrl)
    };


    /**
     * Opens the Success Reject modal
     */
    openSuccessRejectFormPrompt = () => {
        this.setState({
            showSuccessRejectFormPrompt: true,
        })
    }

    /**
     * Closes the Success Reject modal
     */
    onClosingSuccessRejectFormPrompt = () => {
        const {
            navigationUrl,
            history
        } = this.props;

        this.setState({
            showSuccessRejectFormPrompt: false,
        });

        // Navigate to Search Page
        history.push(navigationUrl)
    };

    /**
     * Opens the Success SendTo modal
     */
    openSuccessSendToFormPrompt = () => {
        this.setState({
            showSuccessSendToFormPrompt: true,
        })
    }

    /**
     * Closes the Success SendTo modal
     */
    onClosingSuccessSendToFormPrompt = () => {
        const {
            navigationUrl,
            history
        } = this.props;

        this.setState({
            showSuccessSendToFormPrompt: false,
        });

        // Navigate to Search Page
        history.push(navigationUrl)
    };

    /**
     * Open a Dialog Modal to notify user that create/update was successful
     */
    openSuccessSaveFormPrompt = () => {
        this.setState({
            showSuccessSaveFormPrompt: true,
        })
    };

    /**
     * Closes the Success save/update modal
     */
    onClosingSuccessSaveFormPrompt = () => {
        const { navigationUrl, history, formIsEditMode, isBeneficiaryUser,refetchApplicationFormData } = this.props;

        console.log("onClosingSuccessSaveFormPrompt called with:");
        console.log("navigationUrl:", navigationUrl);
        console.log("history:", history);
        console.log("formIsEditMode:", formIsEditMode);
        console.log("isBeneficiaryUser:", isBeneficiaryUser);

        this.setState({
            showSuccessSaveFormPrompt: false,
        });

        // Refetch application form data if the user is a beneficiary in edit mode
        if (isBeneficiaryUser && formIsEditMode) {
            refetchApplicationFormData();
        } else {
            // Navigate to Search Page if not a beneficiary in edit mode
            history.push(navigationUrl);
        }
    };



    render() {
        const {
            showSendToButton = false,
            showApproveButton = false,
            showRejectButton = false,
            isApproveButtonDisabled,
            isRejectButtonDisabled,
            isSendToButtonDisabled,
            isSaveButtonDisabled,
            formIsEditMode,
            formIsCreateMode,
            sendToButtonTitle,
			showAddComments
        } = this.props;

        const {
            // Prompts State
            showSendToFormPrompt,
            showSuccessSendToFormPrompt,
            showApproveFormPrompt,
            showSuccessApproveFormPrompt,
            showRejectFormPrompt,
            showSuccessRejectFormPrompt,
            showCancelFormPrompt,
            showSaveFormPrompt,
            showPendingFormPrompt,
            showSuccessSaveFormPrompt
        } = this.state;

        return (
            <>
                <Row>
                    <Col xs={12}>
                        <Card border="light" bg="primary" className="bg-white shadow-sm mb-4">
                            <Card.Body>
                                <ButtonGroup className="pull-right">
                                    <Button
                                        disabled={isSaveButtonDisabled}
                                        onClick={this.openSaveFormPrompt}
                                        variant="primary"
                                        className="m-1"
                                    >
                                        <FontAwesomeIcon icon={faSave} className="me-2"/>
                                        {formIsCreateMode ? i18next.t("generic.create") : i18next.t("generic.save")}
                                    </Button>
                                    {showApproveButton &&
                                    <Button
                                        disabled={isApproveButtonDisabled}
                                        onClick={this.openApproveFormPrompt}
                                        variant="success"
                                        className="m-1"
                                    >
                                        <FontAwesomeIcon icon={faCheck} className="me-2"/>
                                        {i18next.t("generic.approve")}
                                    </Button>}
                                    {showRejectButton &&
                                    <Button
                                        disabled={isRejectButtonDisabled}
                                        onClick={this.openRejectFormPrompt}
                                        variant="danger"
                                        className="m-1"
                                    >
                                        <FontAwesomeIcon icon={faTimesCircle} className="me-2"/>
                                        {i18next.t("generic.reject")}
                                    </Button>}
                                    {showSendToButton &&
                                    <Button
                                        disabled={isSendToButtonDisabled}
                                        onClick={this.openSendToFormPrompt}
                                        variant="secondary"
                                        className="m-1"
                                    >
                                        <FontAwesomeIcon icon={faPaperPlane} className="me-2"/>
                                        {sendToButtonTitle}
                                    </Button>}
                                    <Button
                                        onClick={() => this.cancelFormPromptToggle(true)}
                                        variant="warning"
                                        className="m-1"
                                    >
                                        <FontAwesomeIcon icon={faTimesCircle} className="me-2"/>
                                        {i18next.t("generic.cancel")}
                                    </Button>
                                </ButtonGroup>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <FormActionsPromptsModule
                    showCancelFormPrompt={showCancelFormPrompt}
                    showSaveFormPrompt={showSaveFormPrompt}
                    showSuccessSaveFormPrompt={showSuccessSaveFormPrompt}
                    showPendingFormPrompt={showPendingFormPrompt}
                    showApproveFormPrompt={showApproveFormPrompt}
                    showRejectFormPrompt={showRejectFormPrompt}
                    showSendToFormPrompt={showSendToFormPrompt}
                    showSuccessApproveFormPrompt={showSuccessApproveFormPrompt}
                    showSuccessRejectFormPrompt={showSuccessRejectFormPrompt}
                    showSuccessSendToFormPrompt={showSuccessSendToFormPrompt}
                    onClosingCancelFormPromptCallback={() => this.cancelFormPromptToggle(false)}
                    onConfirmCancelFormPromptCallback={this.onConfirmCancelAction}
                    onClosingSaveFormPromptCallback={this.onClosingSaveFormPrompt}
                    onConfirmSaveFormPromptCallback={this.onSaveAction}
                    onClosingSuccessSaveFormPromptCallback={this.onClosingSuccessSaveFormPrompt}
                    onClosingApproveFormPromptCallback={this.onClosingApproveFormPrompt}
                    onConfirmApproveFormPromptCallback={this.onApproveAction}
                    onClosingSuccessApproveFormPromptCallback={this.onClosingSuccessApproveFormPrompt}
                    onClosingRejectFormPromptCallback={this.onClosingRejectFormPrompt}
                    onConfirmRejectFormPromptCallback={this.onRejectAction}
                    onClosingSuccessRejectFormPromptCallback={this.onClosingSuccessRejectFormPrompt}
                    onClosingSendToFormPromptCallback={this.onClosingSendToFormPrompt}
                    onConfirmSendToFormPromptCallback={this.onSendToAction}
                    onClosingSuccessSendToRejectFormPromptCallback={this.onClosingSuccessSendToFormPrompt}
                    formIsEditMode={formIsEditMode}
                    formIsCreateMode={formIsCreateMode}
					showAddComments={showAddComments}
                />
            </>
        )
    }
}

export default withRouter(GenericFormMenuModule);
