import React from "react";
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Routes } from "../../../routing/routes";
import AuthService from "../../../_services/authorization/authService";
import { withStyles } from '@material-ui/core/styles';
import Copyright from '../../loginPage/Copyright';
import { Card, Col, Row } from "@themesberg/react-bootstrap";
import i18next from "../../../translations/i18nConfigInstance";
import ggpsLogo from "../../../assets/img/ggps/ggpsLogo.png";
import { BeatLoader } from "react-spinners";

const styles = (theme) => ({
	paper: {
		margin: theme.spacing(8, 4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%',
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		textTransform: 'none'
	},
});

type Props = {
	updateParentForm: Function
}





class TaxisNetValidationFormComponent extends React.Component<Props> {
	constructor(props) {
		super(props);
		this.state = {
			username: "",
			password: "",
			loading: false,
			errors: {},
			userError: "",
			passwordError: "",
			validUsername: true,
			validPassword: true,

			pendingGettingTaxisNetValidationData: false,
			errorGettingTaxisNetValidationData: false,
			taxisNetValidationData: null,
		};
	}

	onChangeUsername = (e) => {
		this.setState({
			username: e.target.value
		});
	}

	onChangePassword = (e) => {
		this.setState({
			password: e.target.value
		});
	}

	validate = () => {
		let errors = {};
		let isValid = true;

		if (this.state.username === undefined || this.state.username === '') {
			errors['username'] = i18next.t('authPages.usernameEmpty');
			this.setState({ validUsername: false });
			isValid = false;
		}
		if (this.state.password === undefined || this.state.password === '') {
			errors['password'] = i18next.t('authPages.passwordEmpty');
			this.setState({ validPassword: false });
			isValid = false;
		}

		this.setState({
			userError: errors.username,
			passwordError: errors.password,
		});

		return isValid;
	}


	goToTaxisLoginPage = () => {
		window.location.href = "https://oauth2.gsis.gr/oauth2server/oauth/authorize?client_id=LGQIH510474&redirect_uri=https://socialppel.gr/api/tnet/rcb&response_type=code&scope=read"
	}

	onValidateUserCredentialsViaTaxisNet = () => {

		// e.preventDefault();

		const {
			username,
			password
		} = this.state;

		const {
			updateParentForm
		} = this.props;

		// if (this.validate()) {
			this.setState({
				pendingGettingTaxisNetValidationData: true,
			});

			const { urlParams } = this.props;

			AuthService.validateUserViaTaxisNetCode(
				urlParams.code
			).then(
				response => {
					this.setState({
						pendingGettingTaxisNetValidationData: false,
						errorGettingTaxisNetValidationData: false,
						taxisNetValidationData: response.data,
					});

					updateParentForm(response.data);
				})
				.catch((error) => {
					console.log("Error getting TaxisNet Validation Data: ", error);
					this.setState({
						pendingGettingTaxisNetValidationData: false,
						errorGettingTaxisNetValidationData: true,
					})
				});
		// }
	};

	componentDidMount() {
		const { urlParams } = this.props;
		if (typeof urlParams.code !== "undefined" && urlParams.code !== null && urlParams.code !== "") {
			this.onValidateUserCredentialsViaTaxisNet();
		}
	}

	render() {
		const { classes, urlParams } = this.props;

		const {
			username,
			userError,
			validUsername,
			password,
			passwordError,
			validPassword,

			pendingGettingTaxisNetValidationData,
			errorGettingTaxisNetValidationData,
			taxisNetValidationData

		} = this.state;

		return (

			<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>

				<Card border="light" className="bg-white shadow-sm"
					style={{ marginTop: '10px', marginLeft: '10px', marginRight: '10px' }}>
					<Card.Body>
						<div className={classes.paper}>

							<Row>
								<Col xs={12} xl={12}>
									<img src={ggpsLogo} alt="ggpsLogo" />
								</Col>
							</Row>

							<Row>
								<Col xs={12} xl={12}>
									<h5>{i18next.t('authPages.taxisNetValidation')}</h5>
								</Col>
							</Row>

							<form className={classes.form}>
								{/* <TextField
                                    InputProps={{
                                        error: !validUsername
                                    }}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    value={username}
                                    onChange={this.onChangeUsername}
                                    id="username"
                                    label={i18next.t('authPages.username')}
                                    name="username"
                                    autoComplete="email"
                                    helperText={userError}
                                    autoFocus
                                    disabled={pendingGettingTaxisNetValidationData}
                                />
                                <TextField
                                    InputProps={{
                                        error: !validPassword
                                    }}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label={i18next.t('authPages.password')}
                                    type="password"
                                    value={password}
                                    onChange={this.onChangePassword}
                                    helperText={passwordError}
                                    id="password"
                                    autoComplete="current-password"
                                    disabled={pendingGettingTaxisNetValidationData}

                                /> */}

								<div className="d-flex align-content-center justify-content-center">
									<BeatLoader
										size={35}
										color={"#4A5073"}
										loading={pendingGettingTaxisNetValidationData}
									/>
								</div>

								<Button
									onClick={this.goToTaxisLoginPage}
									disabled={pendingGettingTaxisNetValidationData}
									type="button"
									fullWidth
									variant="contained"
									color="primary"
									className={classes.submit}
								>
									{i18next.t('authPages.taxisNetValidationButton')}
								</Button>

								<Grid container>
									<Grid item>
										<Link to={Routes.Signin.path} className="fw-bold" variant="body2">
											{i18next.t('authPages.alreadyHaveAnAccount')}
										</Link>
									</Grid>
								</Grid>

								<Box mt={5}>
									<Copyright copyrightTitle={i18next.t('generic.ppel')} />
								</Box>
							</form>
						</div>
					</Card.Body>
				</Card>
			</Grid>
		);
	}
}

export default withStyles(styles)(TaxisNetValidationFormComponent);
