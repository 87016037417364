const LOCAL_STORAGE_AUTH_USER_DATA = process.env.REACT_APP_AUTH_USER_DATA;
const ROLE_BENEFICIARY = process.env.REACT_APP_AUTH_USER_ROLE_BENEFICIARY;
const ROLE_BACK_OFFICER = process.env.REACT_APP_AUTH_USER_ROLE_BACKOFFICER;

/**
 * Retrieves user data from local storage
 * @returns {any}
 */
const getUserDataFromLocalStorage = () => {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_AUTH_USER_DATA))
};

/**
 * Checks if a Role exists in User Roles from local storage
 * @param roleToBeChecked
 * @returns {boolean}
 */
const checkUserRolesForSpecificRole = (roleToBeChecked) => {
    const userDataFromLocalStorage = getUserDataFromLocalStorage();
    if(userDataFromLocalStorage.roles && userDataFromLocalStorage.roles.length > 0) {
        const found = userDataFromLocalStorage.roles.findIndex(item => item.authority === roleToBeChecked);
        return found !== -1;
    } else {
        return false;
    }
};

/**
 * Checks is at least one Route Permitted Role exists in a User's Roles from localstorage
 * @param permittedRouteRolesArray
 * @param userRolesArray
 * @returns {boolean}
 */
const checkPermittedRolesForRouteAgainstUserRoles = (permittedRouteRolesArray, userRolesArray) => {
    let userIsPermittedToSeeRouteContent = false;
    permittedRouteRolesArray.forEach((permittedRouteRoleItem, permittedRouteRoleIndex) => {
        userRolesArray.forEach((userRoleItem, userRoleIndex) => {
            if(permittedRouteRoleItem === userRoleItem.authority) {
                userIsPermittedToSeeRouteContent = true;
            }
        })
    });
    return userIsPermittedToSeeRouteContent;
}
//
// * Retrieves user ID from local storage data
// * @returns {string | null}
// */
const getUserId = () => {
    const userDataFromLocalStorage = JSON.parse(localStorage.getItem('AUTH_USER_DATA'));
    return userDataFromLocalStorage ? userDataFromLocalStorage.userId : null;
};

const userAccessLevelUtil = {

    // Checks if Beneficiary User only if ROLE_BENEFICIARY is assigned AND NOT the ROLE_ADMIN
    isBeneficiaryUser: function() {
        const isBeneficiaryUser = checkUserRolesForSpecificRole(ROLE_BENEFICIARY);
        const isAdminUser = checkUserRolesForSpecificRole(ROLE_BACK_OFFICER);
        return isBeneficiaryUser && !isAdminUser;
    },

    // Checks if BackOfficer User only if ROLE_BENEFICIARY AND ROLE_ADMIN is assigned
    isBackOfficerUser: function() {
        const isAdminUser = checkUserRolesForSpecificRole(ROLE_BACK_OFFICER);
        return isAdminUser;
    },

    checkPermittedRolesForRouteAgainstUserRoles,
    getUserDataFromLocalStorage,
    getUserId
}



export default userAccessLevelUtil;
