import React from 'react';
import {withRouter} from "react-router-dom";
import {Card, Col, Row} from "@themesberg/react-bootstrap";
import GenericTableComponent from "../../components/genericTableComponent/GenericTableComponent";
import i18next from "../../translations/i18nConfigInstance";
import ApplicationFormTableRowComponent from "./components/ApplicationFormTableRowComponent";
import ApplicationFormSearchFilterComponent from "./components/searchFilter/ApplicationFormSearchFilterComponent";
import applicationFormService from "../../_services/beneficiaryServices/applicationFormService";
import userAccessLevelUtil from "../../utils/userAccessLevelUtil";
import {CreateNewButtonComponent} from "../../components/CreateNewButtonComponent";
import beneficiaryService from "_services/beneficiaryServices/beneficiaryService";
import SweetAlert from "react-bootstrap-sweetalert";

const totalItemsPerPage = 5;

const beneficiaryTableHeaderTitles = [
    i18next.t("applicationForm.id"),
    i18next.t("applicationForm.type"),
    i18next.t("applicationForm.status"),
    i18next.t("applicationForm.submittedAt"),
    i18next.t("applicationForm.actionAt"),
    i18next.t("applicationForm.actionByUsername"),
    i18next.t("generic.actions")
];

const backOfficerTableHeaderTitles = [
    i18next.t("applicationForm.id"),
    i18next.t("applicationForm.beneficiaryId"),
    i18next.t("beneficiaryForm.amka"),
    i18next.t("beneficiaryForm.afm"),
    i18next.t("applicationForm.type"),
    i18next.t("applicationForm.status"),
    i18next.t("applicationForm.submittedAt"),
    i18next.t("applicationForm.actionAt"),
    i18next.t("applicationForm.actionByUsername"),
    i18next.t("generic.actions")
];

class BeneficiariesApplicationFormsListPage extends React.Component {

    _userDataFromLocalStorage = userAccessLevelUtil.getUserDataFromLocalStorage();
    _isBeneficiaryUser = userAccessLevelUtil.isBeneficiaryUser();
    _isBackOfficerUser = userAccessLevelUtil.isBackOfficerUser();

    /**
     * Checks if beneficiary user so that can apply MANDATORY search filter beneficiaryId
     * In other case API will reject the request as Unauthorized.
     * beneficiaryId is used as reference point so that a beneficiary can only access
     * it's own records.
     * BackOfficer Users use the beneficiaryId as OPTIONAL filter
     * @returns {{}}
     */
    initSearchFilters = () => {
        let searchFilters = {};
        if (this._isBeneficiaryUser) {
            searchFilters = {
                beneficiaryId: this._userDataFromLocalStorage.userId
            }
        }
        return searchFilters;
    }

    state = {
        // Records from Network Request
        pendingGettingRecords: false,
        errorGettingRecords: false,
        recordsData: null,
        hasAddresses: false,
        hasCommunicationWays: false,
        showAlert: false,
        // Search Inputs state
        searchFilters: this.initSearchFilters(),
    };

    componentDidMount(): void {
        this.fetchApplicationFormsList();
        this.checkRequiredData();
    }


    checkRequiredData = () => {
        if (this._isBackOfficerUser) {
            return;
        }
        const userId = userAccessLevelUtil.getUserId();
        console.log("userId", userId);
        beneficiaryService.getSingle(userId)
            .then(response => {
                const hasAddresses = response.addresses && response.addresses.length > 0;
                const hasCommunicationWays = response.communicationWays && response.communicationWays.length > 0;
                this.setState({
                    hasAddresses,
                    hasCommunicationWays,
                    showAlert: !hasAddresses || !hasCommunicationWays
                });
            })
            .catch(error => console.log("Error fetching required data: ", error));
    };


    fetchApplicationFormsList = (pageNumber, pageSize, searchFilterData) => {
        this.setState({
            pendingGettingRecords: true,
        });

        applicationFormService.getList(pageNumber, pageSize, this.state.searchFilters)
            .then((response) => {
                this.setState({
                    pendingGettingRecords: false,
                    errorGettingRecords: false,
                    recordsData: response
                })
            })
            .catch((error) => {
                console.log("Error getting Application Forms List  records ", error);
                this.setState({
                    pendingGettingRecords: false,
                    errorGettingRecords: true
                })
            });
    };

    filterSearchRequestCallback = (pageNumber, pageSize, searchFilterData) => {
        this.setState({
            searchFilters: {
                ...searchFilterData,
                ...this.initSearchFilters(), // with that way we ensure always the data of initialSearchFilter will override other same data
            }
        }, () => {
            this.fetchApplicationFormsList(pageNumber, pageSize, searchFilterData);
        });
    }

    onNewButtonPress = () => {
        const { hasAddresses, hasCommunicationWays } = this.state;
        if (!hasAddresses || !hasCommunicationWays) {
            this.setState({ showAlert: true });
        } else {
            this.props.history.push('/applicationForm/new');
        }
    };

    render() {

        const {
            // Records from Network Request
            pendingGettingRecords,
            errorGettingRecords,
            recordsData,
            showAlert
        } = this.state;

        return (
            <>
                <Row>
                    <Col xs={12}>
                        <Card border="light" className="customSectionHeaderStyle shadow-sm mb-4">
                            <Card.Body>
                                <h4 style={{color: "#fefefe"}}>
                                    {this._isBeneficiaryUser &&
                                    i18next.t("applicationForm.page.searchPageTitleBeneficiary")
                                    }

                                    {this._isBackOfficerUser &&
                                    i18next.t("applicationForm.page.searchPageTitleBackOfficer")
                                    }
                                </h4>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <ApplicationFormSearchFilterComponent
                    filterSearchRequestCallback={this.filterSearchRequestCallback}
                    pendingGettingRecords={pendingGettingRecords}
                    isBackOfficerUser={this._isBackOfficerUser}
                />

                {this._isBeneficiaryUser &&
                <div className="table-settings mt-2 mb-2">
                    <Row className="justify-content-between align-items-center">
                        <Col className="ps-md-0 text-end float-end">
                            <CreateNewButtonComponent
                                isDisabled={!this.state.hasAddresses || !this.state.hasCommunicationWays || this._isBackOfficerUser}
                                onPressCallback={this.onNewButtonPress}/>
                        </Col>
                    </Row>
                </div>}

                <Row>
                    <Col xs={12}>
                        <GenericTableComponent
                            recordsData={recordsData?.elements}
                            stickyLastColumn={true}
                            pendingGettingRecordsData={pendingGettingRecords}
                            errorGettingRecordsData={errorGettingRecords}
                            tableHeaderTitles={this._isBackOfficerUser ? backOfficerTableHeaderTitles : beneficiaryTableHeaderTitles}
                            tableRowComponent={
                                <ApplicationFormTableRowComponent
                                    isBeneficiaryUser={this._isBeneficiaryUser}
                                    isBackOfficerUser={this._isBackOfficerUser}
                                />
                            }
                            serverSidePaginationEnabled={true}
                            totalItemsPerPage={totalItemsPerPage}
                            paginatedData={recordsData}
                            serverSidePaginationServiceCallback={this.fetchApplicationFormsList}
                        />
                    </Col>
                </Row>
                {showAlert && (
                    <SweetAlert
                        warning
                        title="Απαιτούμενες Πληροφορίες Λείπουν"
                        onConfirm={() => this.setState({ showAlert: false })}
                        confirmBtnText="Εντάξει"
                    >
                        Πρέπει να έχετε εiσάγει διεύθυνση και τρόπο επικοινωνίας για να δημιουργήσετε νέα αίτηση.
                    </SweetAlert>
                )}
            </>
        )
    }
}

export default withRouter(BeneficiariesApplicationFormsListPage);
